<template>
  <div class="container-fluid">
    <list-desktop
      v-if="!$isMobile()"
      :empty="empty"
      :loading="loading"
      :pagination="getP"
      :queriedData="queriedData"
      :data="data"
      :total="total"
      v-on:load_invoices="loadInvoices"
      v-on:load_invoice_back="loadInvoiceBack"
      v-on:details="details"
      v-on:export_pdf="exportPdf"
      v-on:delete_facture="deleteFacture"
      v-on:delete_facture_multiple="deleteFactureMultiple"
      v-on:print_facture_multiple="printFactureMultiple"
      v-on:search_q="searchBack"
      v-on:per_page_selected="nbrPerPageSelected"
      v-on:send_invoice="sendInvoice"
    >
    </list-desktop>

    <list-mobile
      v-if="$isMobile()"
      :empty="empty"
      :loading="loading"
      :pagination="getP"
      :queriedData="queriedData"
      :total="total"
      v-on:search_q="searchBack"
      v-on:load_invoices="loadInvoices"
      v-on:load_invoice_back="loadInvoiceBack"
      v-on:details="details"
      v-on:export_pdf="exportPdf"
      v-on:delete_facture="deleteFacture"
      v-on:send_invoice="sendInvoice"
    >
    </list-mobile>

    <mdb-modal
      size="md"
      centered
      :show="modalSender.show"
      @close="modalSender.show = false"
    >
      <form @submit.prevent="sendByMail">
        <mdb-modal-header>
          <mdb-modal-title> {{ modalSender.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <p v-if="!modalSender.content.customer_email">
            Aucune adresse e-mail n'a été enregistrée au nom du client
            <b>{{ modalSender.content.customer_name }}</b
            >. <br />
            Veuillez renseigner l'email de destination.
          </p>
          <p v-else>
            Une adresse e-mail a été enregistrée au nom du client
            <b class="pr-3">{{ modalSender.content.customer_name }}</b>
            <b>{{ modalSender.content.customer_email }}</b>
            <br />
            Vous pouvez toutefois changer l'adresse puis appuyer sur envoyer.
          </p>

          <div class="row">
            <div class="col-sm-12">
              <mdb-input
                wrapperClass=""
                inputClass="border-reversed color-normal-reversed"
                labelClass=" color-normal-reversed"
                type="email"
                outline
                size="lg"
                label="Adresse Email"
                v-model="modalSender.content.customer_email"
                placeholder="dupont@gmail.co"
              ></mdb-input>
            </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <button
            class="btn btn-md btn-primary"
            type="submit"
            :disabled="modalSender.btn_sender"
          >
            <span v-if="!modalSender.btn_sender"
              ><i class="fas fa-paper-plane"></i> Envoyer</span
            >
            <span
              v-if="modalSender.btn_sender"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="modalSender.btn_sender" class="pl-2">Loading...</span>
          </button>
          <button
            type="button"
            class="btn btn-md btn-outline-primary"
            @click="hideModalSender"
          >
            Annuler
          </button>
        </mdb-modal-footer>
      </form>
    </mdb-modal>
  </div>
</template>
<script>
import download from "../../../services/download-fetch";
import ListDesktop from "./modules/ListDesktop";
import ListMobile from "./modules/ListMobile";
import {
  mdbModal,
  mdbModalTitle,
  mdbModalBody,
  mdbModalHeader,
  mdbModalFooter,
  mdbInput,
} from "mdbvue";
export default {
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - FACTURES",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    ListDesktop,
    ListMobile,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    mdbModalHeader,
    mdbModalFooter,
    mdbInput,
  },

  data() {
    return {
      empty: true,
      loading: false,
      dataPagination: {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [
            { text: "10", value: "10", selected: true },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: [
          "type",
          "num_facture",
          "date_facture",
          "client_specification",
          "customers",
          "depots",
          "accounting_categories",
          "users",
          "payments",
          // "is_active",
          // "uid"
        ],
      },
      modalSender: {
        show: false,
        title: "Envoyer une facture",
        content: {
          customer_email: "",
          customer_name: "",
          invoice: "",
          invoiceuid: "",
        },
        btn_sender: false,
      },

      data: {
        columns: [
          {
            label: "facture#",
            field: "invoice_number",
            sort: true,
            role: true,
          },
          {
            label: "Date",
            field: "date_facture",
            sort: true,
            role: true,
          },
          {
            label: "Type",
            field: "type",
            sort: true,
            role: true,
          },
          {
            label: "Client",
            field: "customer_id",
            sort: true,
            role: true,
          },
          {
            label: "Paiement",
            field: "payment_id",
            sort: true,
            role: true,
          },
          {
            label: "Vendeur",
            field: "users",
            sort: true,
            role: true,
          },
          {
            label: "Etat",
            field: "normalize",
            sort: true,
            role: true,
          },

          {
            label: "",
            field: "action",
            sort: true,
            role: true,
          },
        ],
        invoices: {
          current_page: 1,
          data: [],
        },
      },
    };
  },

  methods: {
    //Fonction de fonctionnement
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalCustomer.title = "Modifier un client";
        this.modalCustomer.content = data;
        this.modalCustomer.edit = true;
        this.formRest(data);
      } else {
        this.modalCustomer.title = "Ajouter un client";
        this.modalCustomer.content = {};
        this.modalCustomer.edit = false;
        this.cleanForm();
      }

      this.modalCustomer.show = true;
    },

    loadInvoiceBack(e) {
      this.loadInvoices({
        page: e.page,
        perPage: e.perPage,
      });
    },

    async exportPdf(row) {
      let th = 0;
      let doyouPrint = await this.$store.dispatch("swal/doYouPrintPdf");

      if (doyouPrint.isConfirmed) {
        th = 0;
      } else if (doyouPrint.isDenied) {
        th = 1;
      } else {
        return;
      }

      this.$nprogress.start();
      await download({
        url: "/print/pdf/" + row.id + "?th=" + th,
        isTh: th,
        filename: row.num_facture + ".pdf",
      })
        .then(() => {
          this.$nprogress.done();
          this.$notify({
            message: "PDF Généré avec succès",
            type: "success",
          });
        })
        .catch(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Echec durant l'exportation, reessayer ulterieurement",
            type: "danger",
          });
        });
    },

    async sendInvoice(row) {
      if (!row.uid) {
        this.$notify({
          message: "La facture n'est pas encore normalisée ",
          type: "danger",
        });
        return;
      }

      this.modalSender.content.customer_name =
        row.customers.designation.toLowerCase() !== "divers" && !row.client_specification
          ? row.customers.designation
          : row.client_specification;
      this.modalSender.content.customer_email = row.customers.email;
      this.modalSender.content.invoiceuid = row.uid;
      this.modalSender.content.invoice = row.id;

      this.modalSender.show = true;

      // await this.sendByMail().then(() => {
      //   this.hideModalSender();
      // });
    },

    async sendByMail() {
      if (!this.modalSender.content.invoiceuid) {
        this.$notify({
          message: "La facture n'est pas encore normalisée ",
          type: "danger",
        });
        return;
      }
      this.modalSender.btn_sender = !this.modalSender.btn_sender;
      this.$nprogress.start();
      await this.$store
        .dispatch("invoice/sendInvoice", this.modalSender.content)
        .then((response) => {
          this.modalSender.btn_sender = !this.modalSender.btn_sender;
          this.$nprogress.done();
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.hideModalSender();
        })
        .catch((error) => {
          this.modalSender.btn_sender = !this.modalSender.btn_sender;
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    hideModalSender() {
      this.modalSender.content.customer_email = "";
      this.modalSender.content.customer_name = "";
      this.modalSender.content.invoice = "";
      this.modalSender.content.invoiceuid = "";
      this.modalSender.show = false;
    },

    async loadInvoices({ page, perPage }) {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("invoice/findAll", {
          page: page,
          perPage: perPage,
        })
        .then((response) => {
          this.$nprogress.done();
          if (response.data.invoices.data.length > 0) {
            this.empty = false;
          } else {
            this.empty = true;
          }
          this.loading = !this.loading;

          this.data.invoices = response.data.invoices;
          this.dataPagination.pagination.currentPage =
            response.data.invoices.current_page;
          this.dataPagination.pagination.perPage = response.data.invoices.per_page;
          this.dataPagination.pagination.total = response.data.invoices.total;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;

          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    details({ id }) {
      this.$router.push({
        path: "/factures/details/" + id,
      });
    },

    async deleteFacture(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      this.$nprogress.start();
      await this.$store
        .dispatch("invoice/delete", id)
        .then((response) => {
          this.$nprogress.done();
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadInvoices({
            page: this.data.invoices.current_page,
            perPage: this.dataPagination.pagination.perPage,
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteFactureMultiple(factures) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }

      this.$nprogress.start();
      await this.$store
        .dispatch("invoice/deleteMulti", { factures: factures })
        .then((response) => {
          this.$nprogress.done();
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadInvoices({
            page: this.data.invoices.current_page,
            perPage: this.dataPagination.pagination.perPage,
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async printFactureMultiple(factures){
      let th = 0;
      this.$nprogress.start();
      await download({
        url: "/invoices/print/pdf",
        isTh: th,
        method: "POST",
        filename: "factures" + ".zip",
        data: factures
      })
        .then(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Votre telechargement commence dans quelques secondes",
            type: "success",
          });
        })
        .catch(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Echec durant l'exportation, reessayer ulterieurement",
            type: "danger",
          });
        });
    },

    searchBack(e) {
      this.dataPagination.searchQuery = e;
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.invoices.total);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        return new Error("Nothing To Search");
      }

      let result = this.data.invoices.data.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          if (typeof row[key] === "object") {
            switch (key) {
              case "depots":
                rowValue = row[key] ? row[key].designation.toString().toLowerCase() : "";
                break;

              case "customers":
                rowValue = row[key] ? row[key].designation.toString().toLowerCase() : "";
                break;

              case "accounting_categories":
                rowValue = row[key] ? row[key].category.toString().toLowerCase() : "";
                break;

              case "payments":
                rowValue = row[key] ? row[key].moyens.toString().toLowerCase() : "";
                break;
              case "users":
                rowValue = row[key]
                  ? row[key].firstname.toString().toLowerCase() +
                    " " +
                    row[key].lastname.toString().toLowerCase()
                  : "";
                break;
              default:
                break;
            }
          }

          if (typeof row[key] === "string") {
            switch (key) {
              case "uid":
                rowValue = row[key] ? "normalisée" : "en attente";
                break;
              default:
                rowValue = row[key].toString().toLowerCase();

                break;
            }
          }
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(e) {
      let nbr = e;
      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      this.loadInvoices({
        page: 1,
        perPage: nbr,
      });
      return (this.dataPagination.pagination.perPage = nbr);
    },

    setTotal() {
      if (this.dataPagination.searchQuery === "") {
        this.dataPagination.pagination.total = this.data.invoices.total;
      }
    },
  },

  created() {
    this.loadInvoices({
      page: this.data.invoices.current_page,
      perPage: this.dataPagination.pagination.perPage,
    });
  },

  mounted() {},

  computed: {
    pageData() {
      return this.data.invoices.data;
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.data.invoices.total < parseInt(highBound)) {
        highBound = this.total;
      }
      return parseInt(highBound);
    },
    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    queriedData() {
      let result = this.searchQ();
      this.setTotal();
      return result instanceof Error ? this.pageData : result;
    },

    total() {
      return this.totalCalcul();
    },

    getP() {
      return this.dataPagination;
    },
  },
};
</script>
