<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class=" " style="font-weight:400" >Factures</h3>
            <mdb-btn
                    class="float-right"
                    tag="a"
                    color="primary"
                    icon="plus"
                    size="sm" 
                    small
                    @click="$router.push({name:'Creer Facture'})"
                    v-if="$gate.hasPermission(['create::invoice'])"
                >
                </mdb-btn>
        </div>

        <div class="card card-search-os mb-4 px-0 py-0" style="border-radius:10px">
            <div class="card-body px-0 py-0">

                <div class="tec-search-v-icon">
                    <input   style="background:transparent"  v-model="pagination.searchQuery" type="search" ref="searchbar" name="search" placeholder="recherche ..." id="tec-search-v" class="tec-search-v">
                    <i class="fa fa-search fa-md fa-fw" aria-hidden="true"></i>
                </div>
            </div>
        </div>

        <div class="card card-list-os" v-if="empty" v-loading="loading" >
            <div class="card-body text-center">
                <div  class="d-flex justify-content-center w-100 p-5">
                    <img  class="w-50" :src="require('../../../../assets/svg/empty_streec.svg')"   alt="" srcset="">
                </div>
                    <h6>Aucune donnée</h6>
            </div>
        </div>
        
        <div class="" v-if="!empty" v-loading="loading">
            <div class="card card-list-os mb-3" style=""  v-for="row in queriedData" :key="row.name">
                <div class="card-body py-1 pl-2 d-flex align-items-center justify-content-between" >
                    <div class="" style="max-width:90%">
                        <div class="mobile-card-header" style="">
                            <span>  </span>
                            <span class="badger pb-2 d-block" style="font-size:0.8rem">
                                <span v-if="row.uid" class="badge badge-success"> normalisée </span>
                                <span v-if="!row.uid" class="badge badge-warning"> en attente </span>
                            </span>
                        </div>
                        <h5 class="card-title  pb-2 "  
                            style="font-size:0.9rem;font-weight:700;font-family:'Nunito'"> 
                            <span style="text-transform:uppercase">N° {{row.num_facture}}</span>  
                            <span style="text-transform:uppercase" v-if="row.customers.designation.toLowerCase() !== 'divers'">
                            | {{ row.customers.designation}}
                            </span>
                            <span style="text-transform:uppercase" v-else-if="row.customers.designation.toLowerCase() === 'divers' && row.client_specification">
                            | {{row.client_specification}}
                            </span>
                            <span style="text-transform:uppercase" v-else>
                            | {{ row.customers.designation}}
                            </span>
                        </h5>
                        <div class="mt-n3 mobile-card-describe" style="font-weight:500;text-transform:uppercase;font-size:0.75rem; color:rgba(62, 69, 81, .7);">
                            <span class="item-mobile-spa">{{row.date_facture}}</span>
                            <span class="item-mobile-spa">{{row.type}}</span>
                            <span class="item-mobile-spa">{{row.payments.moyens}} </span>
                            <span class="item-mobile-spa">{{ row.users.firstname }}  <span style="text-transform:uppercase;"> {{ row.users.lastname }}</span></span>
                        </div> 
                    </div>
                    <div class="action">
                        <button class="btn-mob " @click="showAction(row)" >
                            <i class=" color-normal-reversed fas fa-ellipsis-v"></i>
                        </button>
                    </div>    
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <tec-pagination
                    v-model="pagination.pagination.currentPage"
                    :per-page="parseFloat(pagination.pagination.perPage)"
                    :total="pagination.pagination.total"
                    v-on:changePageEvent="loadInvoiceBack"
                ></tec-pagination>
            </div>
        </div>
        <mdb-modal  color="primary" centered size="sm" position="bottom" direction="bottom" :show="modalAction.show" @close="hideAction()">
            <mdb-modal-body class="px-0 py-0">
                <mdb-list-group flush>
                    <mdb-list-group-item  
                        class="bg-primary white-text font-weight-bold" > 
                        <div>MENU</div> 
                        <button class="btn-mob" @click="hideAction()"> 
                        <i class="fas fa-times"></i> 
                        </button> 
                    </mdb-list-group-item>
                    <mdb-list-group-item  
                        v-if="$gate.hasPermission(['view::invoice'])"
                        class="menu-tec-lg"
                        @click.native="details({num_facture:modalAction.content.num_facture,id:modalAction.content.id})"
                        icon="align-center"  
                        style="font-weight:400;">
                        <div class="pl-3 menu-mob-tec">Voir les détails</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  
                        v-if="$gate.hasPermission(['view::invoice'])"
                        class="menu-tec-lg"
                        @click.native="exportPdf(modalAction.content)" :disabled="!modalAction.content.uid"
                        :class="( modalAction.content.uid) ?'black-text' :'grey-text'" icon="file"  
                        style="font-weight:400;" ><div class="pl-3 menu-mob-tec">Exporter en Pdf</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  
                        v-if="$gate.hasPermission(['view::invoice'])"
                        class="menu-tec-lg"
                        @click.native="sendInvoice(modalAction.content)" :disabled="!modalAction.content.uid"
                        :class="( modalAction.content.uid) ?'black-text' :'grey-text'" icon="paper-plane"  
                        style="font-weight:400;" ><div class="pl-3 menu-mob-tec">Envoyer par mail</div>
                    </mdb-list-group-item>
                    <mdb-list-group-item  class="menu-tec-lg"
                        v-if="$gate.hasPermission(['delete::invoice'])"
                        @click.native="deleteFacture(modalAction.content.id)" 
                        :disabled="modalAction.content.uid" :class="(modalAction.content.uid) ?'grey-text' :'text-danger'"  
                        icon="trash"  style="font-weight:400;" >
                        <div class="pl-3 menu-mob-tec">Supprimer la facture</div>
                    </mdb-list-group-item>
                </mdb-list-group>
            </mdb-modal-body>
        </mdb-modal>
    </div>
</template>
<script>
import TecPagination from '@/tba/components/PaginationUrl'

import {
    mdbBtn,
    mdbModal,  mdbModalBody,
    mdbListGroup, mdbListGroupItem ,
} from 'mdbvue'
export default {
    components:{
        mdbBtn,
        mdbModal,  mdbModalBody,
        mdbListGroup, mdbListGroupItem ,
        TecPagination
    },
    props:{
        empty:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
        pagination:{
            type:Array,
            default: ()=>[]
        },
        data:{
            type:Array,
            default:() => []
        },
        pageData:{
            type: Array,
            default: () => []
        },
        queriedData:{
            type: Array,
            default: () => []
        },
        total:{
            type: Number,
            default: () => 0
        },
    },
    data(){
        return {
            modalAction:{
                show:false,
                content:{}
            }
        };
    },
    methods:{
        deleteInvoice(e){
            this.modalAction.show = false
            this.$emit('delete_invoice',e)
            
        },

        exportPdf(e){
            this.modalAction.show = false
            this.$emit('export_pdf',e)
        },

        // sendInvocie(e){
        //     this.modalAction.show = false
        //     this.$emit('send_invoice',e)
        // },

        sendInvoice (data){
            this.modalAction.show = false
            this.$emit('send_invoice',data)
        },

        details(e){
            this.modalAction.show = false
            this.$emit('details',e)
            
        },

        deleteFacture(e){
            this.modalAction.show = false
            this.$emit('delete_facture',e)
            
        },

        loadInvoiceBack(e){
            this.$emit('load_invoice_back',{
                page:e,
                perPage:this.pagination.pagination.perPage
            })
        },

        // Systeme de datatable
        totalCalcul () {
            return this.pagination.pagination.total = this.data.invoices.total;
        },

        showAction(data){
            this.modalAction.content  = data
            this.modalAction.show = true
        },
        hideAction(){
            this.modalAction.content  = {}
            this.modalAction.show = false
        },

        searchQ () {
            if (!this.pagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.invoices.data.filter((row) => {
                let isIncluded = false;
                for (let key of this.pagination.propsToSearch) {
                    let rowValue = "";
                    if (typeof row[key] === "object") {
                        switch (key) {
                            case "depots":  
                                rowValue = (row[key]) ? (row[key].designation).toString().toLowerCase() : '';
                                break;
                            
                            case "customers":  
                                rowValue = (row[key]) ? (row[key].designation).toString().toLowerCase() : '';
                                break;

                            case "accounting_categories":  
                                rowValue = (row[key]) ? (row[key].category).toString().toLowerCase() : '';
                                break;
                            
                            case "payments":  
                                rowValue = (row[key]) ? (row[key].moyens).toString().toLowerCase() : '';
                                break;
                            case "users":  
                                rowValue = (row[key]) ? (row[key].firstname).toString().toLowerCase()+ ' '+(row[key].lastname).toString().toLowerCase() : '';
                                break;
                            default:
                                break;
                        }
                    }

                    if (typeof row[key] === 'string') {
                        switch(key){
                            case "uid":
                                rowValue = (row[key]) ?  "normalisée": "en attente" ;
                                break;
                            default:
                                rowValue = row[key].toString().toLowerCase();

                                break;
                        }
                    }
                    if (
                        rowValue.includes &&
                        rowValue.includes(this.pagination.searchQuery.toLowerCase())
                        ) { 
                        isIncluded = true;
                        }   
                }
                return isIncluded;
            });
            this.pagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
            this.$emit('load_invoices',{
                page:1,
                perPage:nbr
            })
           return this.pagination.pagination.perPage = nbr
        },
    },
       
}
</script>

<style lang="scss">
    .btn-mob{
        outline: 0;
        border: 0;
        background-color:transparent;
    }

    /* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
    /* peuvent utiliser différentes fonctions de durée et de temps.  */
    .slide-fade-enter-active {
    transition: all .3s ease;
    }
    .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
    }
</style>