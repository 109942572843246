<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Factures</h4>
      <mdb-btn
        class="float-right"
        tag="a"
        color="primary"
        icon="plus"
        size="sm"
        small
        @click="$router.push({ name: 'Creer Facture' })"
        v-if="$gate.hasPermission(['create::invoice'])"
      >
      </mdb-btn>
    </div>
    <hr class="" />
    <mdb-card cascade>
      <mdb-view
        v-if="false"
        class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between card-header-reversed align-items-center"
        cascade
      >
        <div
          class="title-card-tec card-header-title text-primary color-primary-reversed"
          style="font-weight: 400"
        >
          Factures
        </div>
        <mdb-btn
          class="float-right"
          tag="a"
          color="primary"
          icon="plus"
          size="sm"
          small
          @click="$router.push({ name: 'Creer Facture' })"
        >
        </mdb-btn>
      </mdb-view>

      <mdb-card-body cascade class="" v-loading="loading">
        <mdb-row>
          <mdb-col sm="12" md="4" class="">
            <mdb-select
              flipOnScroll
              outline
              label="Nombre par page"
              class=""
              @getValue="nbrPerPageSelected"
              v-model="pagination.pagination.perPageOptions"
            />
          </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n4"> </mdb-col>
          <mdb-col sm="12" md="4" class="mb-n3">
            <mdb-input
              inputClass="border-reversed color-normal-reversed"
              labelClass=" color-normal-reversed"
              icon="search"
              outline
              type="text"
              v-model="searchQuery"
              @change="search()"
              placeholder="Rechercher"
              aria-label="Search"
            />
          </mdb-col>
        </mdb-row>
        <el-checkbox-group v-model="checkedInv" @change="handleCheckedInvChange">
          <mdb-tbl responsiveMd>
            <mdb-tbl-head>
              <tr class="color-normal-reversed">
                <th colspan="8" class="py-1" style="border-top: 1px solid transparent">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start align-items-center">
                      <el-checkbox
                        :indeterminate="isIndeterminate"
                        :value="isCheck"
                        @change="handleCheckAllChange"
                      ></el-checkbox>
                      <mdb-dropdown class="" tag="a">
                        <mdb-dropdown-toggle
                          slot="toggle"
                          tag="div"
                          outline="none"
                          class="btn-transparent px-2 py-0 z-depth-0"
                          >Selectionner
                        </mdb-dropdown-toggle>
                        <mdb-dropdown-menu color="primary" left>
                          <mdb-dropdown-item @click="checkAllDrop(1)">
                            Factures normalisées
                          </mdb-dropdown-item>
                          <mdb-dropdown-item @click="checkAllDrop(0)">
                            Factures en attente
                          </mdb-dropdown-item>
                        </mdb-dropdown-menu>
                      </mdb-dropdown>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Actualiser"
                        placement="bottom"
                      >
                        <mdb-btn
                          tag="a"
                          class="z-depth-0"
                          color=""
                          floating
                          size="sm"
                          @click="loadInvoiceBack(1)"
                          ><mdb-icon icon="redo-alt" color="primary"
                        /></mdb-btn>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Supprimer"
                        placement="bottom"
                        v-if="checkedInv.length > 0"
                      >
                        <mdb-btn
                          tag="a"
                          class="z-depth-0"
                          color=""
                          floating
                          size="sm"
                          @click="deleteSelection()"
                          ><mdb-icon icon="trash" color="primary"
                        /></mdb-btn>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Telecharger la selection"
                        placement="bottom"
                        v-if="checkedInv.length > 0"
                      >
                        <mdb-btn
                          tag="a"
                          class="z-depth-0"
                          color=""
                          floating
                          size="sm"
                          @click="printAllPdf()"
                          ><mdb-icon icon="download" color="primary"
                        /></mdb-btn>
                      </el-tooltip>
                    </div>
                  </div>
                </th>
              </tr>
            </mdb-tbl-head>

            <mdb-tbl-head>
              <tr class="color-normal-reversed">
                <th v-for="column in data.columns" :key="column.name">
                  {{ column.label }}
                </th>
              </tr>
            </mdb-tbl-head>
            <mdb-tbl-body class="color-normal-reversed">
              <tr class="tec-table-row" v-for="row in queriedData" :key="row.name">
                <th class="tec-table-cell" scope="row">
                  <el-checkbox :key="row.id" :label="row.id">{{
                    row.num_facture
                  }}</el-checkbox>
                </th>
                <td class="tec-table-cell">{{ row.date_facture }}</td>
                <td class="tec-table-cell text-capitalize">{{ row.type }}</td>
                <td class="tec-table-cell">
                  <span v-if="row.customers.designation.toLowerCase() != 'divers'">{{
                    row.customers.designation
                  }}</span>
                  <span
                    v-else-if="
                      row.customers.designation.toLowerCase() == 'divers' &&
                      row.client_specification
                    "
                    >{{ row.client_specification }}
                  </span>
                  <span v-else>{{ row.customers.designation }}</span>
                </td>
                <td class="tec-table-cell">{{ row.payments.moyens }}</td>
                <td class="tec-table-cell" style="text-transform: capitalize">
                  {{ row.users.firstname }}
                  <span style="text-transform: uppercase"> {{ row.users.lastname }}</span>
                </td>
                <td class="tec-table-cell">
                  <span v-if="row.uid" class="badge badge-primary"> normalisée </span>
                  <span v-if="!row.uid" class="badge badge-warning"> en attente </span>
                </td>
                <td class="d-flex justify-content-end">
                  <el-dropdown trigger="click">
                    <span
                      class="el-dropdown-link"
                      style="cursor: pointer; font-weight: 500"
                    >
                      Actions<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                      <el-dropdown-item
                        class="dropdown-item-el-tec"
                        icon="el-icon-notebook-2"
                        @click.native="
                          details({ num_facture: row.num_facture, id: row.id })
                        "
                        v-if="$gate.hasPermission(['view::invoice'])"
                        >Détails</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="dropdown-item-el-tec"
                        icon="el-icon-s-order"
                        v-if="row.uid && $gate.hasPermission(['view::invoice'])"
                        @click.native="exportPdf(row)" 
                        >Exporter PDF</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="dropdown-item-el-tec"
                        icon="el-icon-s-promotion"
                        v-if="row.uid && $gate.hasPermission(['view::invoice'])"
                        @click.native="sendInvoice(row)"
                        >Envoyer par mail</el-dropdown-item
                      >
                      <el-dropdown-item
                        class="dropdown-item-el-tec"
                        icon="el-icon-delete"
                        v-if="!row.uid && $gate.hasPermission(['delete::invoice'])"
                        @click.native="deleteFacture(row.id)"
                        >Supprimer</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
        </el-checkbox-group>

        <div v-if="empty" class="d-flex justify-content-center w-100 p-5">
          <img
            :src="require('../../../../assets/svg/empty_streec.svg')"
            class="empty"
            alt=""
            srcset=""
          />
        </div>

        <div class="d-flex justify-content-end">
          <tec-pagination
            v-model="pagination.pagination.currentPage"
            :per-page="parseFloat(pagination.pagination.perPage)"
            :total="pagination.pagination.total"
            v-on:changePageEvent="loadInvoiceBack"
          ></tec-pagination>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
import TecPagination from "@/tba/components/PaginationUrl";
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbIcon,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
  mdbDropdown,
  mdbDropdownToggle,
  mdbDropdownMenu,
  mdbDropdownItem,
} from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbIcon,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    TecPagination,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    pageData: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      searchQuery: "",

      selection: {
        select_all: false,
        selected: [],
      },

      checkAll: false,
      checkedInv: [],
      isIndeterminate: false,
    };
  },

  methods: {
    handleCheckAllChange(val) {
      if (val) {
        for (const i in this.queriedData) {
          this.checkedInv.push(this.queriedData[i].id);
        }
      } else {
        this.checkedInv = [];
      }

      this.isIndeterminate = false;
    },

    checkAllDrop(mode) {
      this.checkedInv = [];

      if (mode === 1) {
        for (const i in this.queriedData) {
          if (this.queriedData[i].uid) {
            this.checkedInv.push(this.queriedData[i].id);
          }
        }
      } else {
        for (const i in this.queriedData) {
          if (!this.queriedData[i].uid) {
            this.checkedInv.push(this.queriedData[i].id);
          }
        }
      }

      let checkedCount = this.checkedInv.length;
      this.checkAll = this.checkedCount === this.queriedData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.queriedData.length;
    },

    handleCheckedInvChange(value) {
      let checkedCount = value.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.queriedData.length;
      this.checkAll = checkedCount === this.queriedData.length;
    },

    nbrPerPageSelected(value) {
      this.$emit("per_page_selected", value);
    },
    
    deleteInvoice(e) {
      this.$emit("delete_invoice", e);
    },

    exportPdf(e) {
      this.$emit("export_pdf", e);
    },

    sendInvoice(e) {
      this.$emit("send_invoice", e);
    },

    details(e) {
      this.$emit("details", e);
    },

    printAllPdf(){
      this.$emit("print_facture_multiple", this.checkedInv);
    },

    deleteFacture(e) {
      this.$emit("delete_facture", e);
    },

    loadInvoiceBack(e) {
      this.$emit("load_invoice_back", {
        page: e,
        perPage: this.pagination.pagination.perPage,
      });
    },

    deleteSelection() {
      this.$emit("delete_facture_multiple", this.checkedInv);
    },

    search() {
      this.$emit("search_q", this.searchQuery);
    },
  },

  computed: {
    isCheck() {
      return this.checkAll && !this.isIndeterminate;
    },
  },
};
</script>
<style>
label.el-checkbox {
  margin-bottom: 0rem;
}

.el-checkbox {
  color: inherit;
  font-weight: inherit;
}
</style>
